<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'sales-made-boostcredit-lead' }"
        exact
        :active="isTabLead"
        exact-active-class="active"
        :link-classes="['h-full px-3', bgTabsNavs]"
        >Leads
        <span v-if="counterSaleLead" class="ml-1">
          <b-badge pill variant="danger">
            {{ counterSaleLead.total > 99 ? "99+" : counterSaleLead.total }}
          </b-badge>
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'sales-made-boostcredit-client' }"
        exact
        :active="isTabClient"
        exact-active-class="active"
        :link-classes="['h-full px-3', bgTabsNavs]"
      >
        Clients
        <span v-if="G_ADDCHANGE_COUNTERS > 0" class="ml-1">
          <b-badge pill variant="danger">
            {{ G_ADDCHANGE_COUNTERS > 99 ? "99+" : G_ADDCHANGE_COUNTERS }}
          </b-badge>
        </span>
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      G_ADDCHANGE_COUNTERS: "CrmSaleMadeStore/G_ADDCHANGE_COUNTERS",
      G_SALE_LEADS_COUNTER: "CrmSaleMadeStore/G_SALE_LEADS_COUNTER",
      currentUser: "auth/currentUser",
    }),
    programId() {
      return this.$route.matched[1].meta.program;
    },
    isTabLead() {
      return [
        this.$route.matched[2].meta.routePendingLead,
        this.$route.matched[2].meta.routeForApprovedLead,
        this.$route.matched[2].meta.routeDoneLead,
      ].includes(this.$route.name);
    },
    isTabClient() {
      return [
        this.$route.matched[2].meta.routePendingClient,
        this.$route.matched[2].meta.routeForApprovedClient,
        this.$route.matched[2].meta.routeForApprovedClientInProcess,
        this.$route.matched[2].meta.routeForApprovedClientCompleted,
        this.$route.matched[2].meta.routeDoneClient,
      ].includes(this.$route.name);
    },
    counterSaleLead() {
      if (this.G_SALE_LEADS_COUNTER && this.G_SALE_LEADS_COUNTER.total > 0) {
        return this.G_SALE_LEADS_COUNTER;
      }
      return null;
    },
  },
  methods: {},
  async mounted() {},
};
</script>

<style></style>
